import TopSearch from '../top-search';
import { IntegratedNavBar } from './integrated-navbar';
import { useAppContext } from 'context/ConfigurationContext';

export default function AppNavbar(props: {
  className?: string
}) {
  const {configurations} = useAppContext();

  return (
    // z-1000 is currently using for nav
    <nav className="sticky top-0 bg-primary z-[1000]">
      <div className={`flex items-center justify-between !text-neutral-7 w-full container-padding h-[56px] ${props.className}`}>
        <IntegratedNavBar configuration={configurations}/>
        <TopSearch />
      </div>
    </nav>
  );
}
