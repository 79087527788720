import AppImage from "components/image-container"
import { Button } from "components/ui/button"
import { ImageMetadata } from "lib/types/image-meta"
import { Link } from "react-router-dom"

export default function ServiceItemCard(props: {
    image: ImageMetadata,
    name: string,
    shortDescription: string, 
    detailUrl: string
}){
    return (
        <div className="p-3 flex items-center gap-6 ">
            <AppImage 
                containerWidth={214}
                containerHeight={140}
                image={props.image}
            />

            {/* Content */}
            <div className="h-[140px] flex flex-col justify-around">
                <div>
                    <p className="text-neutral-7 font-medium text-[25px] line-clamp-1">{props.name}</p>
                    <p className="text-neutral-7 text-[16px] leading-6 py-3">{props.shortDescription}</p>
                </div>
                <Link to={props.detailUrl}>
                    <Button variant="outline" className="text-neutral-8 hover:text-primary hover:border-primary">Xem chi tiết</Button>
                </Link>
            </div>
        </div>
    )
}