import { ImageMetadata } from "lib/types/image-meta"

export default function GenericTopSection(props: {
  sectionHeader: string,
  image?: ImageMetadata,
  className?: string
}) {
  return (
    <div className={`${props.className}`}>
      <h3 className="mb-[10px] py-2 px-4 text-neutral-8 bg-white rounded-lg">
        {props.sectionHeader}
      </h3>
      {props.image ? (<img loading="lazy" src={props.image.url} alt="product" width={props.image.width} height={props.image.height} />) : <></>}
      
    </div>
  )
}