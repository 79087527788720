import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import AllPostsSection from "./(sections)/all-posts-section";
import AppFooter from "pages/(landing-page)/footer/app-footer";

export default function AllBlogsPage() {
    return (
        <>
          <AppNavbar />
          <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
              <OneLevelBreadcrumb label="Bài viết" />
            </div>
    
            {/* Main section */}
            <main className="w-[1204px] mx-auto mt-[10px]">
              <GenericTopSection
                sectionHeader="Tất cả bài viết"
                image={
                  {
                    url: "/v2/all-services-banner.svg",
                    width: 1204,
                    height: 240
                  }
                }  
              />
              <AllPostsSection />
            </main>
            
            <AppFooter />
          </MainContentLayout>
    
        </>
      )
}