import { ImageMetadata } from "lib/types/image-meta";
import ProductTechnicalInfo from "lib/types/product-technical-info";
import ProductGallery from "./product-gallery";

export default function ProductDetailInformation(props: {
  images: ImageMetadata[],
  isAvailable: boolean,
  type: string,
  technicalInfo: ProductTechnicalInfo,
  fullDescription: string
}) {
  return (
    <div className="w-full flex gap-2">

      {/* Left column with 6 cols */}
      <div className="w-1/2 bg-white rounded-xl p-[16px]">
        <div className="relative w-full h-full">
          {/* TODO: replace with carousel image */}
          <ProductGallery images={props.images} />
        </div>
      </div>

      {/* Right column with 6 cols */}
      <div className="w-1/2 px-[20px] pt-[16px] pb-[20px] bg-white rounded-xl">

        {/* product properties */}
        <div className="w-full p-[16px] border-b-[2px] flex flex-col gap-2 body-text-2">
          {/* Danh muc */}
          <p>
            <span className="font-bold text-neutral-8">Danh mục:&nbsp;</span>
            <span className="text-neutral-7">{props.technicalInfo.type}</span>
          </p>
          {/* Thuong hieu */}
          <p>
            <span className="font-bold text-neutral-8">Thương hiệu:&nbsp;</span>
            <span className="text-neutral-7">{props.technicalInfo.brand}</span>
          </p>
          {/* Ma san pham */}
          <p>
            <span className="font-bold text-neutral-8">Mã sản phẩm:&nbsp;</span>
            <span className="text-neutral-7">{props.technicalInfo.code}</span>
          </p>
          {/* Xuat Xu */}
          <p>
            <span className="font-bold text-neutral-8">Xuất xứ:&nbsp;</span>
            <span className="text-neutral-7">{props.technicalInfo.from}</span>
          </p>
        </div>

        {/* Description */}
        <div className="w-full py-[16px] px-[8px] text-neutral-7 border-b-[2px]">
          <p className="line-clamp-5">
            <div dangerouslySetInnerHTML={{ __html: props.fullDescription }} />
          </p>
        </div>

        {/* button section */}
        <div className="w-full py-[16px] px-[8px] border-b-[2px]">
          <button className="bg-primary w-full rounded-xl text-white py-3 button-text">
            LIÊN HỆ CHÚNG TÔI
          </button>
        </div>

        {/* contact section */}
        <div className="px-[8px] pt-[16px]">
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/verified-icon.svg" alt="verified" width={16} height={16} />
            <p className="body-text-2 text-accent-1">Liên hệ đặt hàng : 0938 172 369 (Mr Hien) </p>
          </div>
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/published-icon.svg" alt="verified" width={16} height={16} />
            <p className="body-text-2 text-accent-1">khanhhienktc@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}