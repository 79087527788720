import ArticleDetail from "lib/models/article-detail";
import { ArticleService } from "lib/services/article.service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function ArticleDetailPage() {
    const { id } = useParams<{ id: string }>();

    const [detail, setDetail] = useState<ArticleDetail | undefined>(undefined);

    useEffect(() => {
        ArticleService.getArticleById(id ?? '').then(res => setDetail(res))
    }, [id])

  return (
    <div>
        {detail?.contentData ? (<div dangerouslySetInnerHTML={{ __html: detail?.contentData }} />) : (<></>)}
    </div>
  )
}