import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "components/ui/breadcrumb";
import HomeBreadcrumbItem from "./home-breadcrumb-item";

export default function OneLevelBreadcrumb(props: {
    label: string
}) {
  return (
    <Breadcrumb className="w-[1204px] mx-auto">
      <BreadcrumbList>
        <HomeBreadcrumbItem />
        <BreadcrumbSeparator />
        <BreadcrumbItem>
            <BreadcrumbPage>{props.label}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}