import ProductBreadcrumb from "components/breadcrumb/products-breadcrumb";
import NotFound from "components/notfound";
import OuterProductDetail from "lib/models/outer-product-detail";
import { OuterProductService } from "lib/services/outer-product.service";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";
import GenericTopSection from "pages/(common)/generic-top-section";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductTypeInformation from "./(sections)/product-type-information";
import TypeProducts from "./(sections)/type-products";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import { GenericServiceItem } from "lib/types/generic-service-item";

export interface ProductTypeDetailMetadata {
    id: string;
    type: string;
    description: string;
    products: GenericServiceItem[][]; // 2 row
    news: GenericServiceItem[];
  }

export default function ProductTypePage() {
    const {type} = useParams<{type: string}>();
    const [detail, setDetail] = useState<OuterProductDetail | undefined>(undefined);

    const typeRouteMetadata: BreadcrumbRouteMetadata = {
        title: type ?? '',
        routerLink: '#'
      }

    useEffect(() => {
        if(type){
            OuterProductService.getOuterProductByPath(type).then(res => {
                setDetail(res)
            })
            
        }
    })
    
      if(!detail){
        return NotFound();
      }
    
      return (
        <>
          {/* Navbar and breadcrumbs */}
          <AppNavbar />
          <MainContentLayout>
            {/* Breadcrumb */}
            <div className="mt-0.5 py-1 rounded-xl">
              <ProductBreadcrumb type={typeRouteMetadata} />
            </div>
    
            {/* Main section */}
            <main className="w-[1204px] mx-auto mt-[10px]">
              <GenericTopSection
                sectionHeader="Gối đỡ vòng bi"
                image={
                  {
                    url: "/generic-product-image.svg",
                    width: 1204,
                    height: 240
                  }
                }  
              />
              <ProductTypeInformation type={detail.name} description={detail.summaryDetail} />
              <TypeProducts title={detail.name} pathType={detail.namePath} products={detail.innerProducts} />
              {/* <ProductsByTypeNews title={metadata.type} news={metadata.news} /> */}
            </main>
    
            <AppFooter />
          </MainContentLayout>
        </>
      )
}