import Required from "components/required-field";
import OuterProductDetail from "lib/models/outer-product-detail";
import OuterProductDto from "lib/models/outer-product.dto";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import FileService from "lib/services/file-service";
import { OuterProductService } from "lib/services/outer-product.service";
import AdminNavbar from "pages/admin/navbar";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UpdateInnerProductSection from "./update-inner-product-section";
import LoadingIndicator from "components/loading";

export default function AdminProductDetailPage() {
    const { outerId } = useParams<{ outerId: string}>();

    const [isLoading, setIsLoading] = useState(false);

    const [detail, setDetail] = useState<OuterProductDetail>();

    const [outerProduct, setOuterProduct] = useState<OuterProductDto>({
        name: '',
        namePath: '',
        summaryDetail: '',
        imageId: 0
    });

    const [outerImageSrc, setOuterImageSrc] = useState<string>('');

    const outerImageInput = useRef(null);
    const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files[0]) {
            setIsLoading(true);
          const fileResponse = await FileService.StoreFile(files[0]);
          setIsLoading(false);
          setOuterImageSrc(fileResponse.outputPath);
          outerProduct.imageId = fileResponse.id;
        }
      };

    const handleSubmitUpdateOuterProduct = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        // post article
        await OuterProductService.updateOuterProduct(outerId ?? '', outerProduct);
    
        window.location.reload();
    }

    useEffect(() => {
        OuterProductService.getOuterProductById(outerId ?? '')
            .then(response => {
                setDetail(response);
            });
    }, [outerId])

    useEffect(() => {
        outerProduct.name = detail?.name ?? '';
        outerProduct.namePath = detail?.namePath ?? '';
        outerProduct.summaryDetail = detail?.summaryDetail ?? '';
        outerProduct.imageId = detail?.imageId ?? 0;

        setOuterProduct({...outerProduct});

        setOuterImageSrc(detail?.image.urlPath ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail])

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />

            {/* Form */}
            <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmitUpdateOuterProduct}>
                <div className="mt-[20px] mb-[10px] mx-[10px]">
                    <div className="flex items-center justify-between mb-[12px]">
                        <h1>Cập nhật sản phẩm</h1>
                        <div className="flex items-center gap-3">
                        <button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>Cập nhật</button>
                        <Link to={`/admin/products/${outerId ?? ''}/new`} className={CommonUsingTailwind.sumbitButton} type="submit">Tạo mới SP</Link>
                            {isLoading ? (<LoadingIndicator />) : (<></>)}
                        </div>
                    </div>
                </div>
                {/* Name input */}
                <div>
                    <label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>Tên sản phẩm <Required /></label>
                    <input
                        id="headerText"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={outerProduct.name}
                        onChange={e => { outerProduct.name = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Tên sản phẩm"
                    />
                </div>

                {/* Name input */}
                <div>
                    <label htmlFor="namePath" className={CommonUsingTailwind.labelClass}>Đường dẫn duy nhất <Required /></label>
                    <input
                        id="namePath"
                        className={CommonUsingTailwind.inputClass}
                        type="text"
                        value={outerProduct.namePath}
                        onChange={e => { outerProduct.namePath = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Đường dẫn"
                    />
                </div>

                {/* Text input */}
                <div>
                    <label htmlFor="summaryDetail" className={CommonUsingTailwind.labelClass}>Tóm tắt sản phẩm <Required /></label>
                    <textarea
                        id="summaryDetail"
                        className={CommonUsingTailwind.inputClass}
                        value={outerProduct.summaryDetail}
                        onChange={e => { outerProduct.summaryDetail = e.target.value; setOuterProduct({ ...outerProduct }) }}
                        placeholder="Tóm tắt"
                    />
                </div>
                
                <div>
                    {/* OuterImage input */}
                    <label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh <Required /></label>
                    <input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
                    {outerImageSrc ? <img loading="lazy" width={100} height={100} src={outerImageSrc} alt='outerImage' /> : <></>}
                </div>

                <hr />
            </form>

            <h1 className="my-[30px] ml-[20px]">Các sản phẩm bên trong</h1>
            {
                detail?.innerProducts.map(innerProduct => (
                    <UpdateInnerProductSection key={innerProduct.id} innerProductDetail={innerProduct} outerProductId={outerId ?? ''} />
                ))
            }
        </main>
    )
}