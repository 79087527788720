import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function TopSearch() {
  const [searchInput, setSearchInput] = useState<string>('');

  return (
    <div className='relative w-max'>
      <input 
        type='text'
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder={`Tìm kiếm sản phẩm`}
        className='w-[320px] h-[40px] rounded-[40px] pl-[18px] py-2 text-[16px] text-neutral-5 focus:outline-offset-1 focus:outline-primary' 
      />
      <Link to={`/search/${searchInput}`} className='absolute w-[35px] h-[35px] rounded-full bg-secondary p-1 right-[2px] top-[2.5px]'>
        <img loading="lazy" src={process.env.PUBLIC_URL + '/v2/search-icon.svg'} width={24} height={24} alt='top-call' />
      </Link>
    </div>
  );
}
