import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import "./index.scss"
import ProductItemCard from "components/product-item-card"
import { OuterProductService } from "lib/services/outer-product.service"
import InnerProduct from "lib/models/inner-product";
import { useEffect, useState } from "react";

export default function OutstandingProducts() {
  const [innerProducts, setInnerProducts] = useState<InnerProduct[]>([]);

  useEffect(() => {
    OuterProductService.getInnerProductOutstanding().then(res => setInnerProducts(res))
  }, [])

  return (
    <div className='bg-neutral-2 pt-[32px] pb-[64px] container-padding'>
        <div className="flex flex-col items-center">
        <p className="text-[30px] text-neutral-9 font-semibold">SẢN PHẨM PHỔ BIẾN</p>
      </div>
      <Tabs defaultValue="first-tab">
        <TabsList className="my-[10px]">
          <TabsTrigger value="first-tab">SẢN PHẨM MỚI</TabsTrigger>
          <TabsTrigger value="second-tab">BÁN CHẠY NHẤT</TabsTrigger>
        </TabsList>
        <TabsContent value="first-tab">
          <div className="px-[24px] outstanding-grid-container">
            {innerProducts.map(item => {
                return (
                    <ProductItemCard 
                        key={item.name}
                        image={{
                            url:item.image.urlPath,
                            width: 198,
                            height: 160,
                            alt: item.name
                        }}
                        name={item.name}
                        detailUrl={`/products/${item.outerProductName}/${item.namePath}`}
                    />
                )
            })}
          </div>
        </TabsContent>
        {/* NOTE: Currently use the same data for both tabs */}
        <TabsContent value="second-tab">
            <div className="px-[24px] outstanding-grid-container">
                {innerProducts.map(item => {
                    return (
                        <ProductItemCard 
                            key={item.name}
                            image={{
                                url:item.image.urlPath,
                                width: 198,
                                height: 160,
                                alt: item.name
                            }}
                            name={item.name}
                            detailUrl={`/products/${item.outerProductName}/${item.namePath}`}
                        />
                    )
                })}
            </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}