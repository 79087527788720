import './index.scss'

export default function AboutUs(){
    return (
        <div className='outer-grid-container mt-[48px] container-padding'>
            <div className='inner-grid-container p-2'>
                <img loading="lazy" width={189} height={148} src={`v2/about-us-1-1.svg`} alt='about-us-img' className='w-full h-full'/>
                <img loading="lazy" width={387} height={300} src={`v2/about-us-1-2-big.svg`} alt='about-us-img' className='w-full h-full big-img'/>
                <img loading="lazy" width={189} height={148} src={`v2/about-us-1-3.svg`} alt='about-us-img' className='w-full h-full'/>
            </div>
            <div className='flex flex-col justify-center'>
                <p className='text-[30px] text-primary'>Về chúng tôi</p>
                <p className='text-[18px] text-neutral-8 font-roboto py-4'>CÔNG TY TNHH KTC VINA được thành lập và chính thức đi vào hoạt động từ tháng 8 năm 2011. Với những dòng sản phẩm chất lượng ổn định, định hướng phát triển đúng đắn, KTC VINA  đã trở thành một thương hiệu mạnh trong lĩnh vực phân phối Vòng bi công nghiệp phân khúc ủy quyền chính hảng tại Việt Nam.</p>
            </div>
            <div className='flex flex-col justify-center'>
                <p className='text-[30px] text-primary'>Chúng tôi luôn tự hào là:</p>
                <ul className='list-disc font-roboto py-4 text-[18px] text-neutral-8'>
                    <li className='font-roboto'>Nhà phân phối Uỷ Quyền Vòng bi Công Nghiệp <a className='underline text-primary' href='#'>INA, FAG, LUK (Schaeffler- Group) -Germany</a> tại Việt Nam.</li>
                    <li className='font-roboto'>Nhà phân phối Uỷ Quyền Vòng bi <a className='underline text-primary' href='#'>NMB-Japan</a>, Các loại vòng bi nhỏ, siêu nhỏ dùng trong các ngành máy công cụ, Dệt may, Nha khoa, Quạt điện.</li>
                    <li className='font-roboto'>Nhà phân phối Uỷ Quyền Dầu Mỡ Bôi trơn <a className='underline text-primary' href='#'>Bechem-Germany</a> - chuyên các loại dầu mỡ đặc chủng trong ngành dệt sợi nhuộm và sản xuất giấy.</li>
                </ul>
            </div>
            <div className='inner-grid-container p-2'>
                <img loading="lazy" width={387} height={300} src={`v2/about-us-2-1-big.svg`} alt='about-us-img' className='w-full h-full big-img'/>
                <img loading="lazy" width={189} height={148} src={`v2/about-us-2-2.svg`} alt='about-us-img' className='w-full h-full'/>
                <img loading="lazy" width={189} height={148} src={`v2/about-us-2-3.svg`} alt='about-us-img' className='w-full h-full'/>
            </div>
        </div>
    )
}