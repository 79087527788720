export class ApiEndpoints {
    public static readonly backendHost = "https://www.ktcvinabearings.com/api";
    public static readonly backendHostImage = "https://www.ktcvinabearings.com";

    // File
    public static readonly publicFilePath = "/static"

    // Article
    public static readonly article = "/Article";
    public static readonly articleByPath = "/Article/Path";
    public static readonly allArticles = "/Article/GetArticles";
    public static readonly allServices = "/Article/GetServices";
    public static readonly allPosts = "/Article/GetPosts";
    public static readonly specificArticle = "/Article/{id}";

    // OuterProduct
    public static readonly outerProduct = "/OuterProduct";
    public static readonly allOuterProduct = "/OuterProduct/GetOuterProducts";
    public static readonly outerProductByPath = "/OuterProduct/GetByPath";
    public static readonly innerProductByPath = "/InnerProduct/GetByPath";
    public static readonly specificOuterProduct = "/OuterProduct/{id}";

    // InnerProduct
    public static readonly innerProduct = "/InnerProduct";
    public static readonly innerProductSearchByName = "/InnerProduct/SearchInnerProductByName";
    public static readonly innerProductOutstanding = "/InnerProduct/GetInnerProductOutstanding";

    // Config
    public static readonly configuration = "/Configuration";
}