import AppNavbar from "./(landing-page)/navbar/app-navbar";
import TopMostBanner from "./(landing-page)/top-most/top-most-banner";
import Firstsponsorsection from "./(landing-page)/first-sponsor-section/first-sponsor-section";
import TopAdvertisement from "./(landing-page)/top-adv";
import ProductsAndServicesSection from "./(landing-page)/products-and-services";
import AboutUs from "./(landing-page)/about-us";
import NewsSection from "./(landing-page)/news";
import OutstandingProducts from "./(landing-page)/outstanding-products";
import AppFooter from "./(landing-page)/footer/app-footer";
import ContactFixed from "./(landing-page)/contact-fixed";
import { ImageCarousel } from "components/carousel/image-carousel";

export default function LandingPage() {
    return (
        <main>
          <TopMostBanner />
          <AppNavbar />
          <ImageCarousel images={[
            {
              url: `v2/banner-image.svg`,
              height: 400,
              width: 1440,
              alt: "banner image"
            }
          ]}/>
          <div className='container-padding'>
            <Firstsponsorsection />
            <TopAdvertisement />
          </div>
          <ProductsAndServicesSection />
          <AboutUs />
          <NewsSection />
          <OutstandingProducts />
          <footer>
            <AppFooter />
          </footer>
          <ContactFixed />
        </main>
    );
}