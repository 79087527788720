import { Carousel, CarouselContent, CarouselItem, TopCarouselNext, TopCarouselPrevious } from "components/ui/carousel";
import { Button } from "components/ui/button"
import InnerProduct from "lib/models/inner-product";
import ProductItemCard from "components/product-item-card";
import { Link } from "react-router-dom";

export default function ProductCarouselBox(
  props: {
    routerLink?: string,
    title: string,
    shortDescription: string,
    items: InnerProduct[],
    className?: string
  }
) {
  return (
    // Container
    <div className={`${props.className} mb-[10px] p-4 bg-white rounded-xl`}>
      <h3 className="text-primary">{ props.title }</h3>
      {props.shortDescription ? (<div className="text-neutral-7 subtitle-1 mt-1">{ props.shortDescription }</div>) : (<></>)}
      <Carousel>
          {/* Buttons section */}
          <div className="flex justify-between my-5">
            <Link to={`${props.routerLink ?? '#'}`}>
              <Button variant="outline" className="text-neutral-8 text-[18px] hover:text-primary hover:border-primary">
                Xem tất cả
              </Button>
            </Link>
            <div className="flex gap-2">
              <TopCarouselPrevious></TopCarouselPrevious>
              <TopCarouselNext></TopCarouselNext>
            </div>
          </div>
          <CarouselContent className="items-start">
            {props.items.map(item => (
              <CarouselItem className="basis-1/5" key={ item.namePath }>
                <ProductItemCard
                  image={{
                    width: 199,
                    height: 160,
                    url: item.image.urlPath,
                    alt: item.image.fileName
                  }}
                  name={item.name}
                  detailUrl={`${props.routerLink}/${item.namePath}`}
                />
            </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
    </div>
  );
}
