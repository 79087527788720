import './index.scss'
import { ConfigurationService } from "lib/services/configuration.service";
import ReferenceData from "common/reference-data";
import { Link } from 'react-router-dom';
import Configuration from "lib/models/configuration";
import { useAppContext } from 'context/ConfigurationContext';

const text = `
Schaeffler sử dụng HEATER-BASIC và HEATER-SMART để đáp ứng yêu cầu tháo lắp ổ trục chuyên nghiệp từ các doanh nghiệp. Cả hai dòng sản phẩm đều phục vụ riêng cho các yêu cầu ứng dụng khác nhau gặp phải trong bảo trì. Tuy nhiên, chúng đều mang đến nhiều lợi thế chung cho khách hàng như:
+ Hệ thống gia nhiệt đồng đều, được kiểm soát để có chất lượng lắp đặt tốt và ổn định
+ Bảo vệ con người và máy móc
+ Giảm chi phí vận hành nhờ tiết kiệm năng lượng và thời gian lắp đặt ngắn
+ Quá trình gia nhiệt diễn ra nhanh chóng và an toàn, đồng thời bảo vệ phôi
+ Làm nóng nhanh và an toàn, ngay cả đối với phôi lớn lên tới 1.600 kg
Đọc thêm tại đây: https://bit.ly/3UHAwZx
--
Additional values from Schaeffler heaters
Schaeffler uses HEATER-BASIC and HEATER-SMART to meet professional bearing disassembly and assembly requirements from businesses. Both product lines cater specifically to the different application requirements encountered in maintenance. However, they all bring many common advantages to customers such as:
+ Uniform, controlled heating system for good and stable installation quality
+ Protect people and machines
+ Reduced operating costs thanks to energy savings and short installation time
+ The heating process takes place quickly and safely, while protecting the workpiece
+ Fast and safe heating, even for large workpieces up to 1,600 kg
Read more here: https://medias.schaeffler.vn/en/mount/heater
#Wepioneermotion #SchaefflerIndustrialSolutions
`;

export default function NewsSection(){
    const {configurations} = useAppContext();

    return (
        <div className='min-h-[690px] bg-primary pt-[32px] pb-[64px] container-padding'>
            <div className="flex flex-col items-center mb-[12px]">
                <p className="text-[30px] text-white font-semibold">TIN TỨC & SỰ KIỆN</p>
                <img loading="lazy" src={`v2/news-icon.svg`} width={32} height={32} alt="operate-icon"/>
            </div>
            <div className="min-h-[484px] flex gap-[10px] items-start rounded-lg bg-white">
                <div className="w-2/3 px-[24px] py-[12px]">
                    <div className="pb-3 text-primary w-full border-b-[1px] border-neutral-4 font-roboto font-bold text-[23px]">
                        Bản tin công nghiệp
                    </div>
                    <div className="news-grid-container">
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_1)}`}>
                        <img loading="lazy" width={208} height={112} src={`v2/news-left-1.svg`} alt="news" className="w-full" />
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">Họ đã làm gì để tiết kiệm 50,000 euro mỗi năm trong sản xuất</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                        </Link>
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_BIG)}`} className="news-big-item">
                            <img loading="lazy" width={578} height={286} src={`v2/news-big.svg`} alt="news" className="w-full" />
                            <p className="font-roboto line-clamp-2 text-neutral-8 text-[21px] font-bold mt-2">Lợi ích cộng thêm từ máy gia nhiệt Schaeffler</p>
                            <p className="font-roboto text-neutral-8 text-[13px] line-clamp-4 mt-2">{text}</p>
                            <p className="mt-6 font-roboto text-neutral-8 text-[15px] font-semibold">Ngày đăng: 27/05/2024</p>
                        </Link>
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_2)}`}>
                            <img loading="lazy" width={208} height={112} src={`v2/news-left-2.svg`} alt="news" className="w-full" />
                            <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">Công nghiệp 4.0 của Schaeffler là Giải pháp trọn đời</p>
                            <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                        </Link>
                    </div>
                </div>
                <div className="w-1/3 px-[24px] py-[12px]">
                    <div className="pb-3 text-primary w-full border-b-[1px] border-neutral-4 font-roboto font-bold text-[23px]">
                        Hỗ trợ kĩ thuật
                    </div>
                    <div className="flex flex-col">
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_RIGHT_1)}`} className="py-[12px] flex gap-[12px] items-center">
                            <div>
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-semibold leading-tight">Hỗ trợ gia nhiệt và lắp đặt vòng bi đúng cách</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                            </div>
                            <img loading="lazy" width={80} height={80} src={`v2/news-right-1.svg`} alt="news" />
                        </Link>
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_RIGHT_2)}`} className="py-[12px] flex gap-[12px] items-center">
                            <div>
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">Hỗ trợ gia nhiệt và lắp đặt vòng bi đúng cách</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                            </div>
                            <img loading="lazy" width={80} height={80} src={`v2/news-right-2.svg`} alt="news" />
                        </Link>
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_RIGHT_3)}`} className="py-[12px] flex gap-[12px] items-center">
                            <div>
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">Hỗ trợ gia nhiệt và lắp đặt vòng bi đúng cách</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                            </div>
                            <img loading="lazy" width={80} height={80} src={`v2/news-right-3.svg`} alt="news" />
                        </Link>
                        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.BLOG_RIGHT_4)}`} className="py-[12px] flex gap-[12px] items-center">
                            <div>
                                <p className="mt-1 font-roboto line-clamp-2 text-neutral-8 text-[18px] font-bold leading-tight">Hỗ trợ gia nhiệt và lắp đặt vòng bi đúng cách</p>
                                <p className="mt-1 font-roboto text-neutral-8 text-[13px]"><span className="font-roboto text-neutral-6">Ngày đăng: </span>27/05/2024</p>
                            </div>
                            <img loading="lazy" width={80} height={80} src={`v2/news-right-4.svg`} alt="news" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="mt-[20px] items-start rounded-lg bg-white p-[20px]">
                <div className="mb-[12px] w-full flex items-center justify-between">
                    <div className="text-primary font-roboto font-bold text-[23px]">Video</div>
                    {/* <div className="flex items-center gap-[10px]">
                        <p className="text-primary font-roboto text-[16px] underline hover-text-secondary">Xem thêm video</p>
                        <img loading="lazy" width={41} height={16} src={`v2/go-further.svg`} alt="next-icon" />
                    </div> */}
                </div>
                <div className="flex pb-[20px] justify-between w-full gap-[20px]">
                    <Link to={`/video/${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.VIDEO_1)}`} className="w-1/4">
                        <img loading="lazy" width={276} height={155} src={`v2/video-img-1.svg`} alt="video-img" className="w-full" />
                        <p className="font-roboto text-neutral-8 text-[16px] line-clamp-2 font-bold leading-tight mt-2">Hội thảo Ứng dụng Vòng bi và Giải pháp Bảo trì cho Ngành công nghiệp Giấy và Bao bì.</p>
                    </Link>
                    <Link to={`/video/${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.VIDEO_2)}`} className="w-1/4">
                        <img loading="lazy" width={276} height={155} src={`v2/video-img-2.svg`} alt="video-img" className="w-full" />
                        <p className="font-roboto text-neutral-8 text-[16px] line-clamp-2 font-bold leading-tight mt-2">Hội thảo Ứng dụng Vòng bi và Giải pháp Bảo trì cho Ngành công nghiệp Giấy và Bao bì.</p>
                    </Link>
                    <Link to={`/video/${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.VIDEO_3)}`} className="w-1/4">
                        <img loading="lazy" width={276} height={155} src={`v2/video-img-3.svg`} alt="video-img" className="w-full" />
                        <p className="font-roboto text-neutral-8 text-[16px] line-clamp-2 font-bold leading-tight mt-2">Hội thảo Ứng dụng Vòng bi và Giải pháp Bảo trì cho Ngành công nghiệp Giấy và Bao bì.</p>
                    </Link>
                    <Link to={`/video/${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.VIDEO_4)}`} className="w-1/4">
                        <img loading="lazy" width={276} height={155} src={`v2/video-img-4.svg`} alt="video-img" className="w-full" />
                        <p className="font-roboto text-neutral-8 text-[16px] line-clamp-2 font-bold leading-tight mt-2">Hội thảo Ứng dụng Vòng bi và Giải pháp Bảo trì cho Ngành công nghiệp Giấy và Bao bì.</p>
                    </Link>
                </div>
            </div>
        </div>

    )
}