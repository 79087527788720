"use client";
import { ImageMetadata } from "lib/types/image-meta";
import { AnimatePresence, motion } from "framer-motion";
import {
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
} from "lucide-react";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
  
  export default function ProductGallery({
    images,
  }:
  {
    images: ImageMetadata[];
  }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<"left" | "right">("left");
  
    const slideVariants = {
      hiddenRight: {
        opacity: 0.6,
      },
      hiddenLeft: {
        opacity: 0.6,
      },
      visible: {
        opacity: 1,
      },
      exit: {
        opacity: 0,
        scale: 1,
      },
    };
    const slidersVariants = {
      hover: {
        scale: 1.2,
      },
    };
    const dotsVariants = {
      initial: {
        y: 0,
      },
      animate: {
        y: -5,
        scale: 1.2,
        transition: { type: "spring", stiffness: 1000, damping: "10" },
      },
      hover: {
        scale: 1.1,
        transition: { duration: 0.2 },
      },
    };
  
    const handleNext = () => {
      setDirection("right");
      setCurrentIndex((prevIndex) =>
        prevIndex + 1 === images.length ? 0 : prevIndex + 1
      );
    };
  
    const handlePrevious = () => {
      setDirection("left");
  
      setCurrentIndex((prevIndex) =>
        prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
      );
    };
  
    const handleDotClick = (index: number) => {
      setDirection(index > currentIndex ? "right" : "left");
      setCurrentIndex(index);
    };
  
    const [openZoom, setOpenZoom] = useState(false);
  
    return (
      <div className="carousel flex gap-4 w-full lg:flex-row flex-col h-full">
        <Lightbox
          open={openZoom}
          close={() => setOpenZoom(false)}
          slides={images.map((image) => ({ src: image.url })) || []}
          plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
          animation={{ fade:0, swipe:0 }}
          styles={{
            container: { backgroundColor: "rgba(0, 0, 0, .7)" },
            thumbnailsContainer: { backgroundColor: "rgba(0, 0, 0, .9)" },
          }}
        />
        <div className="hidden lg:flex flex-col gap-4 items-center p-2">
          {/* zoom button */}
          <div
            className="p-2 bg-neutral-1 rounded-[4px] cursor-pointer"
            onClick={() => setOpenZoom(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8 3H3M3 3V8M3 3L10 10"
                stroke="#636E72"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 21L21 21M21 21L21 16M21 21L14 14"
                stroke="#636E72"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* gallery */}
          <div className="flex-1 flex flex-col gap-2 items-center justify-center">
            <motion.div
              className="p-2 flex items-center justify-center cursor-pointer aspect-square"
              variants={slidersVariants}
              whileHover="hover"
              onClick={handlePrevious}
            >
              <ChevronUp
                className="text-neutral-7"
                size={30}
                onClick={handlePrevious}
              />
            </motion.div>
            <div className="flex flex-col gap-[14px]">
              {images.map((image, index) => {
                if (index > 4) return null;
  
                return (
                  <div
                    key={index}
                    className={`relative w-11 h-11 p-[1px] rounded-[4px]  cursor-pointer ${
                      currentIndex === index
                        ? "border-secondary border-[2px]"
                        : "border-neutral-4 border-[0.6px]"
                    }`}
                    onClick={() => handleDotClick(index)}
                  >
                    <img
                      src={image.url}
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                      alt={
                        image.alt || "Product Image"
                      }
                    />
                  </div>
                );
              })}
            </div>
            <motion.div
              className="p-2 flex items-center justify-center aspect-square"
              variants={slidersVariants}
              whileHover="hover"
              onClick={handleNext}
            >
              <ChevronDown className="text-neutral-7 cursor-pointer" size={30} />
            </motion.div>
          </div>
        </div>
  
        {/* image preview */}
        <div className="flex-1 flex flex-col gap-2 w-full">
          {/* {condition && (
            <span className="rounded-[4px] bg-secondary-200 self-end p-1 text-center text-base not-italic font-normal leading-[130%] tracking-[0.08px]">
              {condition}
            </span>
          )} */}
          <div className="relative w-full aspect-video flex-1 overflow-hidden">
            {
              images[currentIndex] ? (
                <AnimatePresence>
                  <motion.img
                    key={currentIndex}
                    src={images[currentIndex].url}
                    alt={images[currentIndex].alt || "Product Image"}
                    className="w-full h-full object-cover rounded-[10px]"
                    initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
                    animate="visible"
                    exit="exit"
                    variants={slideVariants}
                  />
                </AnimatePresence>
              ) : (<></>)
            }
          </div>
          {/* mobile carousel */}
  
          <div className="flex gap-2 self-center">
            {/* {images.map((_, index) => (
              <motion.div
                key={index}
                className={`w-3 h-3 rounded-full bg-neutral-3 cursor-pointer ${currentIndex === index ? "bg-secondary" : ""}`}
                onClick={() => handleDotClick(index)}
                initial="initial"
                animate={currentIndex === index ? "animate" : ""}
                whileHover="hover"
                variants={dotsVariants}
              ></motion.div>
            ))} */}
          </div>
        </div>
        <div className="flex lg:hidden flex-row gap-4 items-center ">
          {/* zoom button */}
          <div
            className="p-2 bg-neutral-1 rounded-[4px] cursor-pointer hidden md:block"
            onClick={() => setOpenZoom(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8 3H3M3 3V8M3 3L10 10"
                stroke="#636E72"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 21L21 21M21 21L21 16M21 21L14 14"
                stroke="#636E72"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* gallery */}
          <div className="flex-1 flex flex-row gap-2 items-center justify-center">
            <motion.div
              className="p-2 flex items-center justify-center cursor-pointer aspect-square"
              variants={slidersVariants}
              whileHover="hover"
              onClick={handlePrevious}
            >
              <ChevronLeft
                className="text-neutral-7"
                size={30}
                onClick={handlePrevious}
              />
            </motion.div>
            <div className="flex flex-row md:gap-[14px] gap-2 max-w-[calc(100vw-32px-92px-15px)] overflow-x-auto scrollbar-hide">
              {images.map((image, index) => {
                if (index > 4) return null;
  
                return (
                  <div
                    key={index}
                    className={`relative w-11 h-11 p-[1px] rounded-[4px]  cursor-pointer ${
                      currentIndex === index
                        ? "border-secondary border-[2px]"
                        : "border-neutral-4 border-[0.6px]"
                    }`}
                    onClick={() => handleDotClick(index)}
                  >
                    <img
                      src={image.url}
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                      alt={image.alt || "Product Image"}
                    />
                  </div>
                );
              })}
            </div>
            <motion.div
              className="p-2 flex items-center justify-center aspect-square"
              variants={slidersVariants}
              whileHover="hover"
              onClick={handleNext}
            >
              <ChevronRight className="text-neutral-7 cursor-pointer" size={30} />
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
  