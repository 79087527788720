import { Carousel, CarouselContent, CarouselItem } from "components/ui/carousel";

export default function Firstsponsorsection() {
  return (
    <div className="py-[32px]">
      <h1 className="tracking-tight pb-[32px] text-neutral-8">
        Chúng tôi tự hào là <span className="text-primary">Nhà phân phối Ủy Quyền </span> của: 
      </h1>
      <div className="py-2 bg-white rounded-lg border-[1px] border-neutral-3">
        <Carousel>
          <CarouselContent className="items-center">
            <CarouselItem className="basis-auto">
              <img loading="lazy" src='/sponsors/first.svg' alt='first-logo' width={0} height={0} style={{ width: 'auto', height: 'auto' }} className="mx-auto" />
            </CarouselItem>
            <CarouselItem className="basis-auto">
              <img loading="lazy" src='/sponsors/fourth.svg' alt='first-logo' width={0} height={0} style={{ width: 'auto', height: 'auto' }} className="mx-auto"/>
            </CarouselItem>
            <CarouselItem className="basis-auto">
              <img loading="lazy" src='/sponsors/third.svg' alt='first-logo' width={0} height={0} style={{ width: 'auto', height: 'auto' }} className="mx-auto"/>
            </CarouselItem>
            <CarouselItem className="basis-auto">
              <img loading="lazy" src='/sponsors/fifth.svg' alt='first-logo' width={0} height={0} style={{ width: 'auto', height: 'auto' }} className="mx-auto"/>
            </CarouselItem>
            <CarouselItem className="basis-auto">
              <img loading="lazy" src='/sponsors/second.svg' alt='first-logo' width={0} height={0} style={{ width: 'auto', height: 'auto' }} className="mx-auto" />
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
}
