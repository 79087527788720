import AppImage from "components/image-container"
import { ImageMetadata } from "lib/types/image-meta"

export default function ServiceTypeInformation(props: {
  description: string,
  image: ImageMetadata
}) {
  return (
    <div className="w-full flex gap-2">

      {/* Left column with 7 cols */}
      <div className="w-7/12 bg-white rounded-xl p-[16px]">
        <p className="body-text-2 text-neutral-7 whitespace-pre-wrap">
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </p>
      </div>

      {/* Right column with 5 cols */}
      <div className="w-5/12 px-[20px] pt-[16px] pb-[20px] bg-white rounded-xl">

        {/* Image container */}
        <AppImage 
          containerWidth={454}
          containerHeight={240}
          image={props.image}
        />
        
        {/* button section */}
        <div className="w-full py-[16px] px-[8px] border-b-[2px]">
          <button className="bg-primary w-full rounded-xl text-white py-3 button-text">
            LIÊN HỆ CHÚNG TÔI
          </button>
        </div>

        {/* contact section */}
        <div className="px-[8px] pt-[16px]">
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/verified-icon.svg" alt="verified" width={16} height={16} />
            <p className="body-text-2 text-accent-1">Liên hệ đặt hàng : 0938 172 369 (Mr Hien) </p>
          </div>
          <div className="flex items-center gap-4">
            <img loading="lazy" src="/published-icon.svg" alt="verified" width={16} height={16} />
            <p className="body-text-2 text-accent-1">khanhhienktc@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}