import NavItem from "./navItem";

export default function AdminNavbar(){
    return (
        <div className="w-full mx-auto bg-white flex justify-between pr-4">
            <div className="flex">
                <NavItem href="/admin/articles" label="Bài viết" />
                <NavItem href="/admin/products" label="Sản phẩm" />
                <NavItem href="/admin/configuration" label="Cấu hình" />
            </div>
            <img loading="lazy" width={169} height={44} src={`/v2/ktc-nav-logo.svg`} alt="nav-logo" />
        </div>
    )
}