import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import MainContentLayout from "./(common)/main-content-layout";
import AppNavbar from "./(landing-page)/navbar/app-navbar";
import GenericTopSection from "./(common)/generic-top-section";
import AppFooter from "./(landing-page)/footer/app-footer";

const text = `
TỰ GIỚI THIỆU                                                                                                                                                                                
CÔNG TY TNHH KTC VINA được thành lập và chính thức đi vào hoạt động từ tháng 8 năm 2011. Với những dòng sản phẩm chất lượng ổn định, định hướng phát triển đúng đắn, KTC VINA  đã trở thành một thương hiệu mạnh trong lĩnh vực phân phối Vòng bi công nghiệp phân khúc ủy quyền chính hảng tại Việt Nam.
 
Chúng tôi luôn tự hào là:
 
 Nhà phân phối Uỷ Quyền Vòng bi Công Nghiệp INA, FAG, LUK (Schaeffler- Group) –Germany tại Việt Nam,
Nhà phân phối Uỷ Quyền  Vòng bi NMB-Japan, Các loại vòng bi nhỏ, siêu nhỏ dùng trong các ngành máy công cụ, Dệt may, Nha khoa, Quạt điện.
Nhà phân phối Uỷ Quyền Dầu Mỡ Bôi trơn Bechem-Germany -> chuyên các loại dầu mỡ đặc chủng trong ngành dệt sợi nhuộm và sản xuất giấy.

Dịch vụ kỹ thuật
Dụng cụ tháo lắp vòng bi, dầu mỡ chuyên dùng cho vòng bi, Dụng cụ đồ nghề sử dụng cho bảo trì sửa chữa cơ khí, các loại cảo chuyên dụng….
Nhận đo kiểm rung động, lắp đặt hệ thống đo rung động, Cân chỉnh đồng tâm trục, Độ căng và độ phẳng dây curoa và các dịch vụ cơ khí khác với chuyên gia rung động có trình độ level III Quốc Tế.
Gia công đúc kim loại cho các chi tiết, bộ phận cơ khí.
-> Với lực lượng kỹ thuật nhiều năm kinh nghiệm, được đào tạo trong nước và quốc tế, Chúng tôi sẽ chọn ra những loại vòng bi, dây curoa, puly … phù hợp với từng vị trí sử dụng của quý công ty nhằm đem lại hiệu quả kinh tế - kỹ thuật cao nhất cho các chi tiết truyền động.

Lịch sử phát triển

               Trên hành trình gần 9 năm qua, bằng niềm đam mê, sự tập trung và kiên định với mục tiêu phân khúc hàng chính hảng chất lượng cao, KTC VINA đã trở thành thương hiệu mạnh trong lĩnh vực cung cấp Vòng bi, công nghiệp phụ trợ. Là đối tác chính của nhiều doanh nghiệp lớn trong nước cũng như các tập đoàn toàn cầu tại Việt Nam.

KTC VINA nhiều năm liền đạt sự tăng trưởng ổn định 20 -> 30% doanh số.

Ngoài trụ sở chính và 2 kho hàng tại TP.HCM, Công ty cũng đã thành lập 1 văn phòng và kho hàng khu vực miền trung tại TP Quảng Ngãi- tỉnh Quảng Ngãi.

Tầm nhìn – Sứ mệnh

              Tầm nhìn

Trở thành nhà cung cấp thiết bị chất lượng được nhiều khách hàng đón nhận và ủng hộ.

 Với phương châm “Cùng khách hàng phát triển bền vững”, KTC VINA luôn xem hiệu quả kinh tế và kỹ thuật trong chuyển động máy móc sản xuất của khách hàng là sự thành công của công ty.

Chúng tôi luôn luôn hướng đến chinh phục các mục tiêu cao hơn và là sự lựa chọn hàng đầu của mọi đối tác và khách hàng ở Việt Nam và quốc tế.

               Sứ mệnh

Nhiệt huyết 

KTC VINA luôn khuyến khích tinh thần làm việc hăng say, tạo một môi trường làm việc thân thiện, chuyên nghiệp, bình đẳng, đề cao giá trị cá nhân để mỗi nhân viên đều có cơ hội học hỏi, phát triển và cống hiến.

Trách nhiệm 

KTC VINA hoạt động với tuân chỉ tất cả Thành viên luôn luôn trách nhiệm với sản phẩm công ty cung cấp.

Thực hiện tốt vai trò của mình đối với nhân viên, đối tác, cộng đồng và xã hội.

Bền vững 

Luôn luôn xem sự thành công của quý khách hàng là kim chỉ nam cho sự phát triển của KTC VINA

Những đường lối, quyết định của Ban lãnh đạo công ty đều hướng tới sự phát triển bền vững, gây dựng nền tảng cho tương lai từ những hành động tích cực trong hiện tại.

Thông tin liên lạc:

CÔNG TY TNHH KTC VINA
Địa chỉ          : 82/24 Hoàng Bật Đạt, P.15, Q.Tân Bình, TP.HCM
Mã số thuế    : 0311098710
Điện thoại     : (028) 3.8153218              Fax: (028) 3.8153219
Mr. Nguyễn Khánh Hiển – Giám Đốc Kinh Doanh
Mobile           : 0938172369 – 0908654877
Email             : khanhhienktc@gmail.com - hien.nguyen@ktcvinabearings.com

Trân Trọng!
`;

export default function AboutUsPage() {
    return (
        <>
        <AppNavbar />
        <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
            <OneLevelBreadcrumb label="Về chúng tôi" />
            </div>

            {/* Main section */}
            <main className="w-[1204px] mx-auto my-[10px]">
                <GenericTopSection
                    sectionHeader="Về chúng tôi"
                    image={
                    {
                        url: "/v2/all-members.svg",
                        width: 1204,
                        height: 400
                    }
                    }  
                />
                <div className="p-[20px] bg-white rounded-xl mt-[20px] whitespace-pre-wrap">
                    {text}
                </div>
            </main>

            <AppFooter />
        </MainContentLayout>
        </>
    )
}