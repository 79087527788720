import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "components/ui/breadcrumb";
import HomeBreadcrumbItem from "./home-breadcrumb-item";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";

export default function ServicesBreadcrumb(props: {
  type?: BreadcrumbRouteMetadata
}) {
  return (
    <Breadcrumb className="w-[1204px] mx-auto">
      <BreadcrumbList>
        <HomeBreadcrumbItem />
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          {
            props.type 
            ? (<BreadcrumbLink href="/services">Dịch vụ</BreadcrumbLink>) 
            : (<BreadcrumbPage>Dịch vụ</BreadcrumbPage>)
          }
        </BreadcrumbItem>
        {props.type ? (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{props.type!.title}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        ) : (<></>)}
      </BreadcrumbList>
    </Breadcrumb>
  )
}