import { Link } from "react-router-dom";

export default function FootNavigate() {
  return (
    <div className="px-3 pt-4 pb-4 grid grid-cols-4 gap-12 bg-white border-b-[1px] border-solid">
      <div>
        <h6 className="text-primary py-1.5">CÔNG TY TNHH KTC VINA</h6>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/location.svg'} alt="location" width={25} height={24} />
          <p className="text-neutral-8 body-text">82/24 Đường Hoàng Bật Đạt, P.15, Q.Tân Bình, TP.Hồ Chí Minh.</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/call.svg'} alt="call" width={25} height={24} />
          <p className="text-neutral-8 body-text">028 3815 3218 (Ms. Nhung)</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/call.svg'} alt="call" width={25} height={24} />
          <p className="text-neutral-8 body-text">0908 654 877 - 0938 172 369 <br /> (Mr Hiển)</p>
        </div>
        <div className="flex items-start gap-1 py-1.5">
          <img loading="lazy" src={process.env.PUBLIC_URL + '/email.svg'} alt="email" width={25} height={24} />
          <p className="text-neutral-8 body-text">khanhhienktc@gmail.com</p>
        </div>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Danh mục sản phẩm</h6>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Dịch vụ</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Sản phẩm</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Công cụ bảo dưỡng</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Hỗ trợ kĩ thuật</Link>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Hỗ trợ khách hàng</h6>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Thông tin thanh toán</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Chính sách đổi trả</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Chính sách vận chuyển</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Chính sách thanh toán</Link>
      </div>
      <div className="flex flex-col">
        <h6 className="text-primary py-1.5">Mạng xã hội</h6>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Fanpage</Link>
        <Link to="#" className="text-neutral-8 hover:text-primary body-text py-1.5">Youtube</Link>
      </div>
    </div>
  )
}