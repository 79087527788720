export default function CertificateSection() {
  return (
    <div className="mt-12 p-3 bg-neutral-2 flex items-center">
      {/* Title */}
      <div className="mr-[200px]">
        <h1 className="text-primary">Certificates</h1>
        <p className="body-text text-primary font-roboto">Chứng nhận chính hãng</p>
      </div>

      {/* Certificate img */}
      <img
        src='/certificate.svg'
        alt="certificate"
        width={133}
        height={64}
      ></img>
    </div>
  )
}