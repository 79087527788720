import * as React from "react"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"
import { ImageMetadata } from "lib/types/image-meta"

export function ImageCarousel(props: {
  images: ImageMetadata[]
}) {
  return (
    <Carousel className="relative w-full">
      <CarouselContent>
        {props.images.map((image, index) => (
          <CarouselItem key={index}>
            <div>
              <img loading="lazy" src={image.url} width={image.width} height={image.height} alt="image-index"/>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="absolute top-[180px] left-5 rounded-xl text-primary bg-white" />
      <CarouselNext className="absolute top-[180px] right-5 rounded-xl text-primary bg-white" />
    </Carousel>
  )
}
