import { ImageMetadata } from "lib/types/image-meta";

export default function AppImage(props: {
  containerWidth: number,
  containerHeight: number,
  image: ImageMetadata
}) {
  return (
    <div className={`min-w-[${props.containerWidth}px]`}>
      <img
        src={props.image.url}
        width={props.image.width}
        height={props.image.height}
        alt={props.image.alt ?? 'image-text'}
        style={{
          objectFit: "contain",
          width: props.containerWidth,
          height: props.containerHeight
        }}
        className="rounded-xl"
      />
    </div>
  )
}