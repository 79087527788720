import { EmailSubscribeInput } from "components/ui/input";

export default function FooterImage() {
  return (
  <div 
    className="w-full h-[321px] bg-cover flex flex-col gap-3 items-center justify-center"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/v2/footer-image.svg)`,
    }}>
    <h2 className="text-white font-roboto">Đăng ký để nhận thêm thông tin mới về sản phẩm</h2>
    <div className="relative w-[40%]">
      <EmailSubscribeInput type="email" placeholder="Nhập địa chỉ email" />
      <button className="bg-primary text-white px-6 py-2 rounded-[10px] text-[18px] absolute top-1 right-1">Đăng ký</button>
    </div>
  </div>
  )
}