import Configuration from "lib/models/configuration";
import { ConfigurationService } from "lib/services/configuration.service";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../navbar";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import Required from "components/required-field";

export default function AdminConfigurationPage() {
    const navigate = useNavigate();

    const [configurations, setConfigurations] = useState<Configuration[]>([]);

    useEffect(() => {
        ConfigurationService.GetAllConfigurations()
        .then(res => setConfigurations(res))
    }, [])

  
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      // post article
      await ConfigurationService.PostConfigurations(configurations);
      alert("Cập nhật cấu hình thành công")
  
      navigate('/admin/configuration');
      window.location.reload();
    }

    const onInputChange = (e: ChangeEvent<HTMLInputElement>, configId: number): void => {
        const instance = configurations.find(x => x.id === configId);
        if(instance){
            instance.value = e.target.value;
        }

        setConfigurations([...configurations]);
    }

    return (
        <main className="w-full min-h-[100vh] bg-neutral-2 text-neutral-9">
            <AdminNavbar />
            <div className="my-[20px] mx-[10px]">
                <div className="flex items-center justify-between mb-[12px]">
                <h1>Cấu hình hệ thống</h1>
                </div>
            </div>

            {/* Form */}
            <form className="px-3 flex flex-col gap-3" onSubmit={handleSubmit}>
                {configurations.map(configuration => (
                    <div key={configuration.id}>
                        <label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>{`[${configuration.configKey}]`} {configuration.label} <Required /></label>
                        <input
                            id={configuration.id + 'identification'}
                            className={CommonUsingTailwind.inputClass}
                            type="text"
                            value={configuration.value}
                            onChange={e => onInputChange(e, configuration.id)}
                        />
                    </div>
                ))}

                <button className={CommonUsingTailwind.sumbitButton} type="submit">sumbit</button>
            </form>
        </main>
    )
}