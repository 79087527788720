"use client"

import Required from "components/required-field";
import ImageResponse from "lib/models/image-response";
import InnerProduct from "lib/models/inner-product";
import InnerProductDto from "lib/models/inner-product.dto";
import CommonUsingTailwind from "lib/services/common-using-tailwind";
import FileService from "lib/services/file-service";
import { OuterProductService } from "lib/services/outer-product.service";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function UpdateInnerProductSection(props: {
	outerProductId: string,
	innerProductDetail: InnerProduct
}) {
	const detail = props.innerProductDetail;
	const [isLoading, setIsLoading] = useState(false);

	const [editorContent, setEditorContent] = useState(props.innerProductDetail.description);
  const quillRef = useRef<ReactQuill | null>(null);
  const [isMounted, setIsMounted] = useState(false);

	const [innerProductDto, setInnerProductDto] = useState<InnerProductDto>({
		name: detail.name,
		description: detail.description,
		imageId: Number(detail.image.id),
		imageIds: detail.innerProductImages.map(x => Number(x.image.id)),
		namePath: detail.namePath,
		outerProductId: Number(props.outerProductId),
		brand: detail.brand,
		madeIn: detail.madeIn,
		outerProductName: detail.outerProductName,
		productSeries: detail.productSeries
	});

	useEffect(() => {
    setIsMounted(true);
  }, []);

	const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
      ]
    }
  }), []);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// eslint-disable-next-line no-restricted-globals
		if(!confirm("Bạn có chắc chắn muốn cập nhật?")){
			return;
		}

		innerProductDto.description = editorContent;
    setInnerProductDto({...innerProductDto});

		await OuterProductService.updateInnerProduct(props.innerProductDetail.id.toString(), innerProductDto);

		alert("Cập nhật thành công")

		window.location.reload();
	}

	const deleteProduct = async () => {
		// eslint-disable-next-line no-restricted-globals
		if(!confirm("Bạn có chắc chắn muốn xóa?")){
			return;
		}

		await OuterProductService.deleteInnerProduct(props.innerProductDetail.id.toString());

		alert("Xóa thành công")

		window.location.reload();
	}

	const [outerImageSrc, setOuterImageSrc] = useState<string>(detail.image.urlPath);
	const [innerImageSources, setInnerImageSources] = useState<ImageResponse[]>(detail.innerProductImages.map(x => x.image));
	const outerImageInput = useRef(null);
	const innerImageInput = useRef(null);

	const handleOuterImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files;
		if (files && files[0]) {
			setIsLoading(true);
			const fileResponse = await FileService.StoreFile(files[0]);
			setIsLoading(false);
			setOuterImageSrc(fileResponse.outputPath);
			innerProductDto.imageId = fileResponse.id;
		}
	};

	const handleInnerImageInputChange = async (event: React.FormEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files;
		if (files && files[0]) {
			setIsLoading(true);
			const fileResponse = await FileService.StoreFile(files[0]);
			setIsLoading(false);
			innerImageSources.push({
				id: fileResponse.id.toString(),
				fileName: fileResponse.fileName,
				urlPath: fileResponse.outputPath,
				createdAt: ''
			});
			setInnerImageSources([...innerImageSources]);
			innerProductDto.imageIds.push(fileResponse.id);
		}
	};

	const deleteImage = (id: string) => {
		const index = innerImageSources.findIndex(x => x.id === id);
		innerImageSources.splice(index, 1);
		innerProductDto.imageIds.splice(index, 1);
		setInnerImageSources([...innerImageSources]);
		setInnerProductDto({...innerProductDto});
	}

	return (
		<form className="px-3 flex flex-col gap-3 p-[20px] border-[1px] rounded-xl m-3 mb-[20px]" onSubmit={handleSubmit}>
			{/* Name input */}
			<div>
				<label htmlFor="headerText" className={CommonUsingTailwind.labelClass}>Tên sản phẩm <Required /></label>
				<input
					id="headerText"
					className={CommonUsingTailwind.inputClass}
					type="text"
					value={innerProductDto.name}
					onChange={e => { innerProductDto.name = e.target.value; setInnerProductDto({ ...innerProductDto }) }}
					placeholder="Tên sản phẩm"
				/>
			</div>

			{/* Description input <simple> */}
			<p className={CommonUsingTailwind.labelClass}>Mô tả <Required /></p>
			<div>{(isMounted &&
				<ReactQuill
					ref={quillRef}
					value={editorContent}
					onChange={setEditorContent}
					modules={modules}
					formats={[
						'header', 'font', 'size',
						'bold', 'italic', 'underline', 'strike', 'blockquote',
						'list', 'bullet', 'indent',
						'link', 'image'
					]}
				/>)}
			</div>


			{/* Path input */}
			<div>
				<label htmlFor="namePath" className={CommonUsingTailwind.labelClass}>Đường dẫn (duy nhất) <Required /> </label>
				<input
					id="namePath"
					className={CommonUsingTailwind.inputClass}
					type="text"
					value={innerProductDto.namePath}
					onChange={e => { innerProductDto.namePath = e.target.value; setInnerProductDto({ ...innerProductDto }) }}
					placeholder="Đường dẫn"
				/>
			</div>

			{/* Brand input */}
			<div>
				<label htmlFor="brand" className={CommonUsingTailwind.labelClass}>Thương hiệu</label>
				<input
					id="brand"
					className={CommonUsingTailwind.inputClass}
					type="text"
					value={innerProductDto.brand}
					onChange={e => { innerProductDto.brand = e.target.value; setInnerProductDto({ ...innerProductDto }) }}
					placeholder="Thương hiệu"
				/>
			</div>

			{/* MadeIn input */}
			<div>
				<label htmlFor="MadeIn" className={CommonUsingTailwind.labelClass}>Xuất xứ</label>
				<input
					id="MadeIn"
					className={CommonUsingTailwind.inputClass}
					type="text"
					value={innerProductDto.madeIn}
					onChange={e => { innerProductDto.madeIn = e.target.value; setInnerProductDto({ ...innerProductDto }) }}
					placeholder="Xuất xứ"
				/>
			</div>

			{/* Series input */}
			<div>
				<label htmlFor="Series" className={CommonUsingTailwind.labelClass}>Series</label>
				<input
					id="Series"
					className={CommonUsingTailwind.inputClass}
					type="text"
					value={innerProductDto.productSeries}
					onChange={e => { innerProductDto.productSeries = e.target.value; setInnerProductDto({ ...innerProductDto }) }}
					placeholder="Series"
				/>
			</div>

			<div>
				{/* OuterImage input */}
				<label htmlFor="outerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh bên ngoài <Required /></label>
				<input id="outerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={outerImageInput} onChange={handleOuterImageInputChange} />
				{outerImageSrc ? <img loading="lazy" width={100} height={100} src={outerImageSrc} alt='outerImage' /> : <></>}
			</div>

			<div>
				{/* InnerImage input */}
				<label htmlFor="innerImageFile" className={CommonUsingTailwind.labelClass}>Hình ảnh bên trong <Required /></label>
				<input id="innerImageFile" className={CommonUsingTailwind.fileInputClass} type='file' accept='image/*' ref={innerImageInput} onChange={handleInnerImageInputChange} />
				{innerImageSources.map(innerImageSource =>
					(
						<div className="relative" key={innerImageSource.id}>
							<img loading="lazy" width={100} height={100} src={innerImageSource.urlPath} alt='outerImage' />
							<button className="absolute top-1 right-1" onClick={() => deleteImage(innerImageSource.id)}>
								<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#EA3323"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
							</button>
						</div>
					)
				)}
			</div>
			
			<div className="flex gap-3 justify-end">
				<button className={isLoading ? CommonUsingTailwind.sumbitButtonDisabled : CommonUsingTailwind.sumbitButton} type="submit" disabled={isLoading}>Cập nhật</button>
				<button className={CommonUsingTailwind.deleteButton} onClick={deleteProduct}>Xóa</button>
			</div>
		</form>
	)
}