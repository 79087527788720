import BlogsBreadcrumb from "components/breadcrumb/blog-breadcrumb";
import ArticleDetail from "lib/models/article-detail";
import { ArticleItem } from "lib/models/article-item";
import { ArticleService } from "lib/services/article.service";
import { BreadcrumbRouteMetadata } from "lib/types/breadcrumb-route-metadata";
import MainContentLayout from "pages/(common)/main-content-layout";
import AppFooter from "pages/(landing-page)/footer/app-footer";
import AppNavbar from "pages/(landing-page)/navbar/app-navbar";
import ServiceCarouselBox from "pages/services/(sections)/service-carousel-box";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function BlogDetailPage() {
    const { id } = useParams<{id: string}>();

    const [articleDetail, setArticleDetail] = useState<ArticleDetail | undefined>(undefined);
    const [mostRelatedArticles, setMostRelatedArticles] = useState<ArticleItem[]>([]);

    const routeMetadata: BreadcrumbRouteMetadata = {
        title: id ?? '',
        routerLink: '#'
    }

    useEffect(() => {
        if(id){
            ArticleService.getArticleUniquePath(id).then(res => {
                setArticleDetail(res)
            })
        }
    }, [id])

    useEffect(() => {
        if(articleDetail){
            ArticleService.getAllPosts().then(res => {
                const mostRelatedServicesData = res.filter(x => x.id !== articleDetail?.id).slice(0, 4);
                setMostRelatedArticles(mostRelatedServicesData);
            })
        }
    }, [articleDetail])

    return (
        <>
            {/* Navbar and breadcrumbs */}
            <AppNavbar />

            <MainContentLayout>
                <div className="mt-0.5 py-1 rounded-xl">
                    <BlogsBreadcrumb type={routeMetadata} />
                </div>

                {/* Main section */}
                <main className="w-[1204px] mx-auto mb-[48px]">
                    <h3 className="mt-[10px] mb-[10px] py-2 px-4 text-neutral-8 bg-white rounded-lg">
                        Blogs: {articleDetail?.headerText}
                    </h3>
                    <div className="rounded-lg p-4 bg-white" dangerouslySetInnerHTML={{ __html: articleDetail?.contentData ?? '' }}></div>

                    {/* Book services */}
                    <ServiceCarouselBox
                        className="mt-[32px]"
                        title={'Một số bài viết khác'}
                        items={mostRelatedArticles}
                        shortDescription={``}
                        routerLink="/blogs"
                    ></ServiceCarouselBox>
                </main>

                <AppFooter />
            </MainContentLayout>
        </>
    )
}