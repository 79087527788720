import OneLevelBreadcrumb from "components/breadcrumb/one-level-breadcrumb";
import MainContentLayout from "./(common)/main-content-layout";
import AppNavbar from "./(landing-page)/navbar/app-navbar";
import GenericTopSection from "./(common)/generic-top-section";
import AppFooter from "./(landing-page)/footer/app-footer";

export default function ContactPage() {
    return (
        <>
        <AppNavbar />
        <MainContentLayout>
            <div className="mt-0.5 py-1 rounded-xl">
            <OneLevelBreadcrumb label="Liên hệ" />
            </div>

            {/* Main section */}
            <main className="w-[1204px] mx-auto my-[10px]">
                <GenericTopSection sectionHeader="Về chúng tôi"/>
                <div className="flex gap-[20px] w-full">
                    <div className="w-[60%] bg-white rounded-lg p-[20px] text-[18px] whitespace-pre-wrap">
                        {`
    CÔNG TY TNHH KTC VINA

    Địa chỉ:  82/24 Đường Hoàng Bật Đạt, Phường 15, Quận Tân Bình, TP. Hồ Chí Minh.
    Hotline: - 028 3815 3218 - Fax: 028 3815 3219
    Website: www.ktcvinabearings.com - Email: khanhhienktc@gmail.com
    Giấy CNĐKDN:  0311098710 - Ngày cấp: 26/08/2011
    Cơ quan cấp: Phòng Đăng ký kinh doanh Sở Kế hoạch và Đầu tư TPHCM
                        `}
                    </div>
                    <div className="w-[40%] bg-white rounded-lg p-[20px]">
                        <div className="w-[460px] h-[440px]">
                            <iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.808894197331!2d106.63198321092723!3d10.825932189281344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175297caee77eef%3A0xad37f20f58d28cad!2zODIgSG_DoG5nIELhuq10IMSQ4bqhdCwgUGjGsOG7nW5nIDE1LCBUw6JuIELDrG5oLCBUUC4gSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1727187391189!5m2!1svi!2s" width="440" height="440" loading="lazy"></iframe>
                        </div>
                        <div className="my-[10px]">Văn phòng: 82 Hoàng Bật Đạt, Phường 15, Tân Bình, Thành phố Hồ Chí Minh </div>
                        {/* button section */}
                        <div className="w-full py-[16px] px-[8px] border-b-[2px]">
                        <a href={`https://maps.app.goo.gl/Rztr7ZXsTpUzP5yd6`} className="bg-primary text-center block w-full rounded-xl text-white py-3 button-text">
                            Tìm đường đến công ty
                        </a>
                        </div>
                    </div>
                </div>
            </main>

            <AppFooter />
        </MainContentLayout>
        </>
    )
}