import ProductCarouselDoubleLineItems from "components/carousel/product-carousel/product-carousel-double-line-items";
import InnerProduct from "lib/models/inner-product";
import { GenericServiceItem } from "lib/types/generic-service-item";

export default function TypeProducts(props: {
  title: string,
  pathType: string,
  products: InnerProduct[]
}) {
  const rowCount = 2;

  let item : GenericServiceItem[] = [];
  const parsedItem : GenericServiceItem[][] = [];

  for(let count = 0; count < props.products.length; count++){
    const product = props.products[count];

    const added : GenericServiceItem = {
      name: product.name,
      image: {
        width: 277,
        height: 182,
        url: product.image.urlPath,
        alt: product.image.fileName
      },
      routerLink: `/products/${props.pathType}/${product.namePath}`
    }

    if(count % rowCount > 0){
      item = [added];
    } else {
      item.push(added);
    }

    if(props.products.length === 1){
      parsedItem.push(item);
    }

    if(count % rowCount === rowCount - 1){
      parsedItem.push(item);
    }
  }

  return (
    <div className="bg-white p-[24px] rounded-xl mt-[10px]">
      <h4 className="text-primary">Sản phẩm - {props.title}</h4>
      <ProductCarouselDoubleLineItems items={parsedItem} />
    </div>
  )
}