export default function MainContentLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <main className="w-[1440px] mx-auto">
      {children}
    </main>
  )
}
