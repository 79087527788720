import axios from "axios";
import { ApiEndpoints } from "./api-endpoints";
import FileService from "./file-service";
import OuterProduct from "../models/outer-product";
import OuterProductDetail from "../models/outer-product-detail";
import OuterProductDto from "../models/outer-product.dto";
import InnerProductDto from "../models/inner-product.dto";
import InnerProductResponse from "../models/inner-product-response";
import InnerProduct from "../models/inner-product";
import SimpleAuthService from "./simple-check-auth.service";

export class OuterProductService {
    public static async getAllOuterProduct() : Promise<OuterProduct[]> {
        const response = await axios.get<OuterProduct[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.allOuterProduct}`);
        return response.data ? response.data.map(x => {
            x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
            x.innerProducts = x.innerProducts.map(i => {
                i.image.urlPath = FileService.GetImageUrl(i.image.urlPath);
                return i;
            });
            return x;
        }) : [];
    }

    public static async getOuterProductByPath(path: string) : Promise<OuterProductDetail> {
        const response = await axios.get<OuterProductDetail>(`${ApiEndpoints.backendHost}${ApiEndpoints.outerProductByPath}/${path}`);
        
        if(response.data){
            response.data.image.urlPath = FileService.GetImageUrl(response.data.image.urlPath);
            response.data.innerProducts = response.data.innerProducts.map(innerProduct => {
                innerProduct.image.urlPath = FileService.GetImageUrl(innerProduct.image.urlPath);
                innerProduct.innerProductImages = innerProduct.innerProductImages.map(x => {
                    x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                    return x
                });
                return innerProduct;
            });
        }
        
        return response.data;
    }

    public static async getInnerProductByPath(path: string) : Promise<InnerProductResponse> {
        const response = await axios.get<InnerProductResponse>(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProductByPath}/${path}`);
        if(response.data){
            response.data.image.urlPath = FileService.GetImageUrl(response.data.image.urlPath);
            response.data.innerProductImages = response.data.innerProductImages.map(x => {
                x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                return x;
            });
            response.data.relatedProducts = response.data.relatedProducts.map(x => {
                x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                return x;
            })
        }
        
        return response.data;
    }

    public static async getOuterProductById(id: string) : Promise<OuterProductDetail> {
        const response = await axios.get<OuterProductDetail>(`${ApiEndpoints.backendHost}${ApiEndpoints.outerProduct}/${id}`);
        
        if(response.data){
            response.data.image.urlPath = FileService.GetImageUrl(response.data.image.urlPath);
            response.data.innerProducts = response.data.innerProducts.map(innerProduct => {
                innerProduct.image.urlPath = FileService.GetImageUrl(innerProduct.image.urlPath);
                innerProduct.innerProductImages = innerProduct.innerProductImages.map(x => {
                    x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                    return x
                });
                return innerProduct;
            });
        }
        
        return response.data;
    }

    public static async searchInnerProduct(name: string): Promise<InnerProduct[]> {
        const response = await axios.get<InnerProduct[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProductSearchByName}/${name}`);
        if(response.data && response.data.length > 0){
            response.data = response.data.map(x => {
                x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                return x;
            })
        }
        return response.data;
    }

    public static async getInnerProductOutstanding(): Promise<InnerProduct[]> {
        const response = await axios.get<InnerProduct[]>(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProductOutstanding}`);
        if(response.data && response.data.length > 0){
            response.data = response.data.map(x => {
                x.image.urlPath = FileService.GetImageUrl(x.image.urlPath);
                return x;
            })
        }
        return response.data;
    }

    public static async postOuterProduct(outerProduct: OuterProductDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.outerProduct}`, outerProduct);
    }

    public static async updateOuterProduct(id: string, outerProduct: OuterProductDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.put(`${ApiEndpoints.backendHost}${ApiEndpoints.outerProduct}/${id}`, outerProduct);
    }

    public static async updateInnerProduct(id: string, innerProduct: InnerProductDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.put(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProduct}/${id}`, innerProduct);
    }

    public static async postInnerProduct(innerProduct: InnerProductDto) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.post(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProduct}`, innerProduct);
    }

    public static async deleteOuterProduct(id: string) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.delete(`${ApiEndpoints.backendHost}${ApiEndpoints.outerProduct}/${id}`);
    }

    public static async deleteInnerProduct(id: string) : Promise<void> {
        if(!SimpleAuthService.Check()){
            alert("Xác thực thất bại!");
            throw new Error("Auth failed");
        }
        await axios.delete(`${ApiEndpoints.backendHost}${ApiEndpoints.innerProduct}/${id}`);
    }
}