import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import SearchPage from './pages/SearchPage';
import VideoPage from './pages/VideoPage';
import AdminPage from './pages/admin/AdminPage';
import AdminConfigurationPage from './pages/admin/configuration/AdminConfigurationPage';
import AdminArticlesPage from './pages/admin/article/AdminArticlesPage';
import AdminArticleDetailPage from './pages/admin/article/AdminArticleDetailPage';
import AdminNewArticlePage from './pages/admin/article/AdminNewArticlePage';
import AdminProductsPage from './pages/admin/product/AdminProductsPage';
import AdminNewOuterProductPage from './pages/admin/product/AdminNewOuterProductPage';
import AdminProductDetailPage from './pages/admin/product/detail/AdminProductDetailPage';
import AdminNewInnerProductPage from './pages/admin/product/detail/AdminNewInnerProductPage';
import AllBlogsPage from './pages/blogs/AllBlogsPage';
import BlogDetailPage from './pages/blogs/BlogDetailPage';
import AllServicesPage from './pages/services/AllServicesPage';
import ServiceDetailPage from './pages/services/ServiceDetailPage';
import AllProductPage from './pages/products/AllProductPage';
import ProductTypePage from './pages/products/ProductTypePage';
import ProductDetailPage from './pages/products/ProductDetailPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/video/:id" element={<VideoPage />} />
        <Route path="/search/:name" element={<SearchPage />} />
        <Route path="/blogs" element={<AllBlogsPage />} />
        <Route path="/blogs/:id" element={<BlogDetailPage />} />
        <Route path="/services" element={<AllServicesPage />} />
        <Route path="/services/:id" element={<ServiceDetailPage />} />
        <Route path="/products" element={<AllProductPage />} />
        <Route path="/products/:type" element={<ProductTypePage />} />
        <Route path="/products/:type/:id" element={<ProductDetailPage />} />

        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/configuration" element={<AdminConfigurationPage />} />
        <Route path="/admin/articles" element={<AdminArticlesPage />} />
        <Route path="/admin/articles/:id" element={<AdminArticleDetailPage />} />
        <Route path="/admin/articles/new" element={<AdminNewArticlePage />} />
        <Route path="/admin/products" element={<AdminProductsPage />} />
        <Route path="/admin/products/new" element={<AdminNewOuterProductPage />} />
        <Route path="/admin/products/:outerId" element={<AdminProductDetailPage />} />
        <Route path="/admin/products/:outerId/new" element={<AdminNewInnerProductPage />} />
      </Routes>
    </Router>
  );
}

export default App;
