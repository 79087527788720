import './index.scss'
import CustomRing from "./custom-ring";
import ProductName from "./product-name";
import ServiceContainer from "./service-container";
import { ConfigurationService } from "lib/services/configuration.service";
import ReferenceData from "common/reference-data";
import { Link } from 'react-router-dom';
import { useAppContext } from 'context/ConfigurationContext';

export default function ProductsAndServicesSection() {
  const {configurations} = useAppContext();

  return (
    <div className='h-[690px] bg-primary pt-[32px] pb-[64px] container-padding'>
      <div className="flex flex-col items-center">
        <p className="text-[30px] text-white font-semibold">SẢN PHẨM & DỊCH VỤ</p>
        <img loading="lazy" src={`v2/operate-icon.svg`} width={32} height={32} alt="operate-icon"/>
      </div>

      {/* Grid container */}
      <div className="grid-container">
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_SP_1)}`}>
          <div className="relative group">
              <img loading="lazy" src={`v2/product-fag.svg`} width={266} height={318} alt="product" className="w-full"/>
              <ProductName productName="VÒNG BI FAG" />
              <CustomRing />
          </div>
        </Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_SP_2)}`}>
          <div className="relative group">
              <img loading="lazy" src={`v2/product-bi.svg`} width={266} height={318} alt="product" className="w-full"/>
              <ProductName productName="GỐI ĐỠ VÒNG BI" />
              <CustomRing />
          </div>
        </Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_SP_3)}`}>
          <div className="relative group">
              <img loading="lazy" src={`v2/product-ina.svg`} width={266} height={318} alt="product" className="w-full"/>
              <ProductName productName="VÒNG BI INA" />
              <CustomRing />
          </div>
        </Link>
        <Link to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_SP_4)}`}>
          <div className="relative group">
              <img loading="lazy" src={`v2/product-nmb.svg`} width={266} height={318} alt="product" className="w-full"/>
              <ProductName productName="VÒNG BI NMB" />
              <CustomRing />
          </div>
        </Link>
        <Link className="grid-span-2 relative" to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_DV_1)}`}>
          <div>
              <img loading="lazy" src={`v2/service-1.svg`} width={569} height={162} alt="product" className="w-full"/>
              <ServiceContainer serviceName="ĐO KIỂM RUNG ĐỘNG" shortDescription="Kiểm tra, đánh giá, giám định kỹ thuật về rung động." />
          </div>
        </Link>
        <Link className="grid-span-2 relative" to={`${ConfigurationService.GetConfigurationValue(configurations, ReferenceData.SPDV_SP_1)}`}>
          <div>
              <img loading="lazy" src={`v2/service-heat.svg`} width={569} height={162} alt="product" className="w-full"/>
              <ServiceContainer serviceName="GIA NHIỆT VÀ LẮP ĐẶT VÒNG BI" shortDescription="Chỉ dẫn kỹ thuật cách gia nhiệt, hỗ trợ lắp đặt vòng bi công nghiệp." />
          </div>
        </Link>
      </div>
    </div>
  );
}
