import AppImage from "components/image-container"
import { Button } from "components/ui/button"
import { ImageMetadata } from "lib/types/image-meta"
import { Link } from "react-router-dom"

export default function ServicesByTypeBox(props: {
  header: string,
  description: string,
  image: ImageMetadata,
  routerLink: string
}) {
  return (
    <div className="p-3 rounded-xl flex items-center gap-6 bg-white mt-[10px] mb-[20px]">
      <AppImage 
        containerWidth={360}
        containerHeight={200}
        image={props.image}
      />

      {/* Content */}
      <div className="pr-[100px] h-[200px] flex flex-col justify-around">
        <div>
          <p className="text-primary font-medium text-[25px]">{props.header}</p>
          <p className="text-neutral-7 text-[16px] leading-6 py-3">{props.description}</p>
        </div>
        <Link to={props.routerLink}>
          <Button variant="outline" className="text-neutral-8 hover:text-primary hover:border-primary">Xem chi tiết</Button>
        </Link>
      </div>
    </div>
  )
}